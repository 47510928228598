.intro {
    height: calc(100vh - 60px);
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 1000px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 130px;
    background-position: center;

    &__heading {
        margin: 0 15px;

        p {
            color: white;
            font-size: rem(20px);
            font-weight: 600;
            text-shadow: 0 0 20px #00000063;
        }
    }

    &__header {
        color: white;
        font-weight: 800;
        text-shadow: 0 0 20px #00000063;
        font-size: rem(50px);
    }

    @include mq($until: xxl) {
        padding-bottom: 110px;
      }
    
      @include mq($until: xl) {
    
      }
    
      @include mq($until: lg) {

      }
    
      @include mq($until: md) {

      }
    
      @include mq($until: sm) {
        height: calc(60vh);
        min-height: 320px;

        &__header {
            font-size: 6vw;
        }

      }
    
      @include mq($until: xs) {
          
      }
}

.map-container {
    margin-top: 15vw;
    height: 30vw;
    margin-bottom: -15vw;

    .map {
        border-radius: $border-r;
    }

    @include mq($until: md) {
        margin-top: 15vw;
        height: 50vw;
        margin-bottom: -25vw;

        .map {
            max-height: unset;
        }
    }
}