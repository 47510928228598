.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 65px;
  background-color: black;

  &__container {
    display: flex;
    align-items: center;
  }

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    width: 140px;
  }

  &__nav {
    margin-right: 50px;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__contact {
    a, span {
      color: #9d9d9d;
      font-style: italic;
      display: flex;
      align-self: center;
      transition: all .3s;
    }

    &:hover {
      a, span {
        color: white;
      }
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

&__menuItem {
    margin-left: 30px;
    padding: 0;
    span, a {
      color: white;
      text-transform: uppercase;
      font-weight: 500;
    }

    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #3e3e3e;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $color-main;
    }
  }

  @include mq($until: xxl) {
    &__logo{
      width: 130px;
    }
    &__menuItem {
      margin-left: 20px;
    }
  }

  @include mq($until: xl) {

  }

  @include mq($until: lg) {
    &__logo{
      width: 100px;
    }
    &__menuItem {
      margin-left: 15px;
    }
  }

  @include mq($until: md) {
    min-height: 50px;

    &__nav {
      margin: 0;
    }

    &__container {
      width: 65%;
      justify-content: space-between;
    }
  }

  @include mq($until: sm) {
    &__logo{
      width: 80px;
    }
  }

  @include mq($until: xs) {

    &__container {
      justify-content: end;
    }
    &__contact {
      margin-right: 25px;
      span {
        &:last-of-type {
          display: none;
        }
      }
    }
  }
}

.drop-menu {
  position: absolute;
  top: 65px;
  right: -2000px; 
  z-index: 2;
  opacity: 0;
  transition: all .5s;

  &__list {
    display: flex;
    margin: 0;
    background-color: #{$color-extra}d9;
    justify-content: space-between;
    padding: 10px;
    border-bottom-right-radius: $border-r;
    border-bottom-left-radius: $border-r;
  }

  &__item {

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      padding: 10px 30px;
      border-radius: $border-r;
      transition: all .3s;
      &:hover {
        background: #5FBCFFe3;
        span {
          color: white;
        }
      }
    }

    img {
      width: 10vw;
      height: 11vw;
      object-fit: contain;
      margin-bottom: 15px;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: rem(15px);
    font-weight: 800;
    color: black;
  }

  @include mq($until: xl) {
    &__title {
      font-size: rem(14px);
    }
  }
}