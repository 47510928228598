.offer {

  display: flex;
  justify-content: space-between;
  margin-top: 200px;
  margin-bottom: 200px;

  &__column {
    width: 24%;
    // height: 33vw;

    &_promo {
      width: 50%;
    }
  }

  &__product {
    align-items: center;
    text-align: center;
    border: 1px solid $szary;
    border-radius: $border-r;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px;
    height: 24vw;
    transition: all .3s;
    &:hover {
      box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.15);
    }

    img {
      height: 78%;
      object-fit: contain;
    }

    h3 {
      font-size: 1vw;
      margin-bottom: 0;
    }

    &_description {

      button {
        background-color: $color-extra;
        font-size: 1.5vw;
        font-weight: 600;
        color: white;
        padding: 1vw 4vw;
        border: 0;
        border-radius: $border-r;
        margin-bottom: 1.5vw;
        transition: all .3s;
        &:hover {
          box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
        }
      }
    }

    &_promo {
      height: 41.98vw;

      img {
        max-height: fit-content;
        height: 75%;
      }
    }
  }

  &__header {
    padding-top: 1.5vw;
    width: 100%;
    border: 1px solid $szary;
    border-radius: $border-r;
    text-align: center;
    margin-bottom: 15px;
    height: 6vw;
  }

  @include mq($until: xl) {
    &__header {
      h2 {
        font-size: 2.1vw;
      }
    }
  }

  @include mq($until: lg) {
    margin-top: 150px;
    margin-bottom: 150px;
    &__product {
        height: 27vw;
        
        &_promo {
            height: 48vw;
        }
    }
  }

  @include mq($until: md) {
    margin-top: 100px;
    margin-bottom: 100px;
    
    &__header {
      margin-bottom: 1.5vw;
      height: auto;
      padding: 15px;
      
      h2 {
        font-size: 3.5vw;
        margin-bottom: 0;
      }
    }

    &__product {
      margin-bottom: 0;
      height: 280px;
      padding: 10px;
      margin-bottom: 1.5vw;

      &_description {
        button {
          font-size: 2vw;
          padding: 2vw 4vw;
          margin-bottom: 3vw;
        }
      }

      &_promo {
          height: 500px; 

          p {
            height: auto;
            font-size: rem(14px);
          }
      }

      h3 {
        font-size: 2.2vw;
      }
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 1.5vw;
    grid-auto-flow: row;
    grid-template-areas:
      "g1 g1"
      "g2 g3";

    
    .g1 { 
      grid-area: g1;
      width: 100%;
    }
    
    .g2 { 
      grid-area: g2;
      width: 100%;
    }
    
    .g3 { 
      grid-area: g3;
      width: 100%;
    }
    
  }

  @include mq($until: xs) {

    &__product {
      margin-bottom: 0;
      height: 280px;
      padding: 10px;
      margin-bottom: 1.5vw;

      &_description {
        button {
          font-size: 4.5vw;
          padding: 3vw 6vw;
        }
      }
    }

    &__header {
      h2 {
        font-size: 4.5vw;
      }
    }

    &__product {
      height: 250px;

      &_promo {
        height: 440px; 
      }

      h3 {
        font-size: 3vw;
        margin-top: 10px;
      }
    }
  }
}