.pop-up {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #{$color-extra}4d;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    display: none;
    opacity: 0;
    transition: all .3s;
    z-index: 5;
    
    form {
        width: 50%;
        background-color: white;
        padding: 50px;
        border-radius: $border-r;
        position: relative;
        margin-bottom: 0;
    }

    &__close {
        position: absolute;
        right: 0;
        top: -10px;
        z-index: 10;
        padding: 20px;
        color: gray;
        font-size: small;
        margin-top: 10px;
    }

    @include mq($until: lg) {
        form {
            width: 80%;
            padding: 25px;
        }
    }

    @include mq($until: xs) {
        form {
            width: 95%;
            padding: 20px;
            .form__row {
                position: relative;
                margin-top: 15px;
            }
        }
    }
}