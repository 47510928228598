.products {

    &__item {
        border: 1px solid $szary;
        border-radius: $border-r;
        max-height: 350px;
        overflow: auto;
        transition: all .3s;
        margin-bottom: 50px;

        h3 {
            font-size: rem(18px);
        }

        &-body {
            display: flex;
        }

        &-img {
            width: 35%;
            text-align: center;
            img {
                max-height: 250px;
            }
        }

        &-excerpt {
            width: 65%;
            padding: 20px;
        }

        &-summary {
            display: flex;
            justify-content: space-between;
            background-color: $szary;
            transition: all .3s;

            ul {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                width: 100%;
                padding: 0 rem(17px);
            }

            li {
                font-size: rem(14px);
                white-space: nowrap;
                padding: rem(5px) 0;
                margin-left: 20px;
                border-right: 1px solid white;
                transition: all .3s;

                strong {
                    text-transform: uppercase;
                    margin: 0 rem(10px);
                    font-weight: 800;
                }

                &:first-of-type {
                    margin-left: 0;
                }
            }

            span {
                transition: all .3s;
                font-size: rem(14px);
                padding: rem(5px) rem(17px) rem(5px) 0;
                font-weight: 700;
                color: $color-extra;
            }
        }

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }

        &:hover {
            border-color: $color-extra;
            .products__item-summary {
                background-color: $color-extra;
                li, span {
                    color: white;
                }
            }

        }
    }

    @include mq($until: sm) {
        &__item {
            height: auto;
            &-body {
                flex-direction: column;
            }

            &-excerpt {
                width: 100%;
                padding: rem(17px);
                p {
                    text-align: justify;
                }
            }

            &-img {
                width: 100%;
                img {
                    max-height: 200px;
                }
            }

            &-summary {
                li {
                    &:last-of-type {
                        border-right: none;
                    }
                }
                span {
                    display: none;
                }
            }
        }
    }

    @include mq($until: xs) {

        &__item {
            &-summary {
                ul {
                    grid-template-columns: 1fr;
                }

                li {
                    margin-left: 0;
                    border: none;
                }
            }
        }
    }
}