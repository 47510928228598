.services{

    margin-bottom: 200px;

	ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, auto);
        gap: 1vw;

        li {
            
            &:first-of-type {
                padding: 1.5vw;
                display: flex;
                align-items: center;
            }
        }

        a {
            padding: 1.5vw;
            border-radius: $border-r;
            display: block;
            border: 1px solid white;
            height: 100%;
            transition: all .3s;

            &:hover {
                border: 1px solid $color-extra;    
            }

            p {
                margin-bottom: 10px;
            }

            &> div {
                display: flex;
                align-items: center;
                margin-bottom: 40px;

                h4 {
                    margin: 0 0 0 40px;
                    font-size: 1vw;
                    font-weight: 800;
                }
            }
        }
    }

    @include mq($until: xl) {
        margin-bottom: 150px;
        ul {
            a {
                &> div {
                    h4 {    
                        font-size: 1.25vw;
                    }   
                }
            }
        }
    }

    @include mq($until: md) {
        margin-bottom: 100px;
        ul {
            gap: 2vw;
            grid-template-columns: repeat(1, 1fr);

            svg {
                width: 40px;
            }

            a {
                padding: 3vw;
                &> div {
                    margin-bottom: 10px;
                    h4 {    
                        font-size: 2vw;
                        margin-left: 20px;
                    }   
                }

                p {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @include mq($until: xs) {

        h2 {
            font-size: 4vw;
        }

        ul {

            svg {
                width: 30px;
            }

            a {
                &> div {
                    margin-bottom: 0;
                    h4 {    
                        font-size: 2.7vw;
                        margin-left: 10px;
                    }   
                }
            }
        }
    }
}

