.subpage {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 70px;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin-bottom: 100px;
    
    h1 {
        font-size: rem(30px);
        text-transform: uppercase;
    }

    h2 {
        font-size: rem(20px);
    }

    h3 {
        font-size: rem(18px);
    }

    h4 {
        font-size: rem(16px);
    }

    h1, h2 {
        font-weight: 900;
    }

    @include mq($until: md) {
        grid-template-columns: 100%;
        margin-bottom: 50px;
    }

    @include mq($until: xs) {
        margin-top: 30px;
        grid-template-columns: 100%;
        margin-bottom: 20px;
    }

}