.second-menu {
	display: flex;
	margin-bottom: 200px;

	h2 {
		color: white;
		font-weight: 800;
		font-size: rem(20px);
		margin-bottom: rem(30px);
	}

	&__toplist {
		margin-top: -80px;
		display: flex;
		background-color: #{$color-extra}d9;
		width: fit-content;
		padding: 30px 60px;
		border-radius: $border-r;

		&>li {
			&:nth-of-type(2) {
				margin: 0 3vw;
				padding: 0 3vw;
				&:before, &:after {
					content: '';
					height: 20px;
					width: 2px;
					height: 40%;
					background-color: rgba(255, 255, 255, 0.473);
					border-radius: 0;
					position: absolute;
					top: 20px;
					right: 0;
					border: none;
				}
			}
		}

	}

	&__item {
		a {
			color: white;
			font-size: 1.125rem;
			padding: 10px;
			display: block;
			line-height: 1;
			transition: all .3s;
			border-radius: 6px;
			&:hover {
				background-color: #7cc0f0;
			}
		}
	}

	&.on-sub {
		
		min-width: 390px;

		.second-menu__toplist {
			flex-direction: column;
			margin-top: 0;
			padding: 20px 25px;
			background-color: #00000000;
			border: 1px solid $szary;
			height: fit-content;

			h2 {
				color: rgb(0, 0, 0);
				font-size: rem(16px);
				margin-bottom: 20px;
			}

			.second-menu__item {

				a {
					color: black;
					font-size: rem(15px);
					font-weight: 500;
					transition: all .2s;

					&:hover {
						background-color: $color-extra;
						color: white;
						border-radius: .3rem;
					}
				}
			}

			&> li:nth-of-type(2) {
				margin: 20px 0;
    			padding: 20px 0;
				&::before {
					content: none;
				}
				&::after {
					content: none;
				}
			}

			@include mq($until: xl) {
				padding: 10px 15px;
			}

			@include mq($until: md) {
				flex-direction: row;
				width: 100%;
				margin-right: $padding-lg;
				justify-content: space-around;

				&> li:nth-of-type(2) {
					margin: 0 15px;
					padding: 0 15px;
				}
			}	

		}

		@include mq($until: xl) {
			min-width: 290px;
		}	

		@include mq($until: md) {
			justify-content: flex-start;
			margin-bottom: 40px;
		}

		@include mq($until: sm) {
			display: none;
		}

	}

	@include mq($until: xxl) {

    }
    
	@include mq($until: xl) {
		margin-bottom: 150px;

		&__item {
			font-size: rem(15px);
		}

		h2 {
			font-size: rem(19px);
			margin-bottom: rem(30px);
		}
	}

	@include mq($until: lg) {

		&__toplist {
			padding: 15px 35px;
		}

		h2 {
			font-size: rem(18px);
			margin-bottom: rem(20px);
		}
	}

	@include mq($until: md) {
		margin-bottom: 100px;
		justify-content: center;

		&__toplist {
			flex-direction: column;
			padding: 30px;
			&>li {
				&:nth-of-type(2) {
					margin: 30px 0;
					padding: 0;
					&:before, &:after {
						content: none;
					}
				}
			}
		}  

		h2 {
			font-size: rem(23px);
			margin-bottom: rem(18px);
		}  
	}

	@include mq($until: sm) {
		&__toplist {
			width: 100%;
			align-items: center;
			li {
				width: 270px;
			}
		}
	}

	@include mq($until: xs) {
		&__toplist {
			flex-direction: column;
			padding: 20px;
			li {
				width: 240px;
			}
			&>li {
				&:nth-of-type(2) {
					margin: 20px 0;
				}
			}
		}  
		
		h2 {
			font-size: rem(20px);
			margin-bottom: rem(15px);
		}  
	}
}


.breadcrumbs {
	position: absolute;
    top: 65px; 
	background-color: $szary;
	padding: 10px 17px;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	left: 0;

	ul {
		margin: 0;
		display: flex;

		li {
			text-transform: lowercase;
			white-space: break-spaces;

			a, span {
				font-size: rem(11px);
				color: gray;
				font-weight: 600;

				&:hover {
					color: $color-extra;
				}
			}

			span {
				color: $color-extra;
			}
		}
	}

	@include mq($until: md) {
		top: 50px;
	}

	@include mq($until: xs) {
		display: none;
	}
}