$pad-xxl: 25px;
$pad-lg: 17px;
$pad-sm: 10px;

.product {
    width: 100%;
    position: relative;
    h2 {
        padding-left: $pad-xxl;
    }
    

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        padding-left: $pad-xxl;

        h1 {
            text-transform: uppercase;
            margin-bottom: 0
        }

        &-contact {
            background-color:  #F9F9F9;
            padding-top: 10px;
            padding-left: 30px;
            padding-bottom: 10px;
            border-top-left-radius: $border-r;
            border-bottom-left-radius: $border-r;
            white-space: nowrap;
            margin-left: 20px;
            span {
                font-size: rem(15px);
                font-weight: 800;
                color: $color-extra;
            }
        }
    }

    &__description {
        padding-left: $pad-xxl;
    }

    &__wrapper {
        border: 1px solid $szary;
        border-radius: $border-r;
        height: 25vw;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 50px;
        margin-bottom: 50px;

        img {
            height: 21vw;
        }
    }

    &__specification {

        li {
            padding: 7px $pad-xxl;
            border-radius: .5rem;
            display: grid;
            grid-template-columns: 40% 60%;
            background-color: $szary;
            margin-bottom: rem(5px);

            &:nth-child(2n) {
                background-color: white;
            }

        }

        span {
            font-size: rem(15px);
        }
    }

    .files_tree {
        margin-top: 115px;
    }
    
    @include mq($until: xxl) {
        &__header {
            margin-top: 80px;
            &> div {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    @include mq($until: xl) {
        padding-left: 25px;

        // &__header {
        //     margin-top: 60px;
        //     &-contact {
        //         position: relative;
        //         top: -60px;
        //     }
        // }

        .files_tree {
            margin-top: 75px;
            padding-right: 90px;
        }
    }

    @include mq($until: lg) {
        .files_tree {
            padding-right: 20px;
        }
    }

    @include mq($until: md) {
        padding-left: 0;

        .files_tree {
            margin-top: 40px;
            padding: 0;
            min-width: 300px;
            width: 85%;
        }

        h2 {
            padding-left: $pad-sm;
        }

        &__specification {

            li {
                padding: 5px $pad-sm;
            }
        }

        &__description {
            padding-left: $pad-sm;
            padding-right: $pad-sm;
            text-align: justify;
        }

        &__header {
            padding-left: $pad-sm;
        }

        &__wrapper {
            height: 55vw;
            img {
                height: 45vw;
            }
        }
    }

    @include mq($until: sm) {

    }

    @include mq($until: xs) {
        &__header {
            &-contact {
                padding-left: 0;
                span {
                    display: none;
                }
            }
        }

        &__wrapper {
            height: 70vw;
            img {
                height: 60vw;
            }
        }
        
        &__specification {
            li {
                grid-template-columns: 100%;
                gap: 1.5vw;
            }
        }
    }
}

/// SLIDER CONTRONLS 

.slide-arrow {
    display: block;
    position: absolute;
    bottom: 50px;
    right: 50px;
    background-color: transparent;
    z-index: 3;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    color: rgb(223, 223, 223);
    border-color: rgb(223, 223, 223);
    border-style: solid;
    font-size: rem(25px);
    transition: all .2s;
    &:hover {
        color: $color-extra;
        border-color: $color-extra;
    }

    &-prev {
        right: 120px;
        i {
            padding-right: 5px;
        }
    }

    &-next {
        i {
            padding-left: 5px;
        }
    }

    &.slick-disabled {
        &:hover {
            color: rgb(223, 223, 223);
            border-color: rgb(223, 223, 223);
        }
    }

    @include mq($until: sm) {
        bottom: 10px;
        right: 10px;
        height: 40px;
        width: 40px;

        &-prev {
            right: 60px;
        }
    }
}

.slick-slider {
    width: 100%;
    position: initial;

    img {
        margin: 0 auto;
    }
}

.raw-content {
    h2 {
        padding-left: 0;
    }
    padding: 25px;
    border: 1px solid $szary;
    border-radius: $border-r;

    @include mq($until: md) {
        padding: 10px;
    }
}

#cat_header {
    margin-left: 20px;
    @include mq($until: sm) {
        margin-left: 1.13333333rem;;
    }
}