.footer {
  background-color: black;
  padding-bottom: 50px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;

  &.homepage {
    padding-top: 20vw;
    @include mq($until: md) {
      padding-top: 35vw;
    }
  }

  h2, h4, a, p, li  {
    color: white;
  }

  h4 {
    font-size: rem(16px);
    font-weight: 800;
  }

  a, p, li {
    line-height: 27.0938px;
  }

  a {
    transition: all .3s;
    &:hover {
      color: $color-extra;
    }
  }

  small {
    font-size: rem(12px);
    font-style: italic;
  }

  &__contact {
    display: flex;
    flex-wrap: wrap;
    &> div:first-of-type {
      margin-right: 150px;
    }
  }

  p, a, li {
    font-size: rem(14px);
  }

  &__list {
    &_left {
      padding: 0;
    }
  }

  &__social-reviews {
    display: flex;
    align-items: center;

    svg {
      fill: white;
      width: 20px;
      margin-left: 20px;
    }
  }

  &__menu {

    &> ul {
      margin-top: 65px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-wrap: wrap;

      &> li{
        margin-left: 2.5vw;
        margin-bottom: 30px;
      }
    }
  }
  

  &__quick-contact {
    display: flex;
    margin-left: -16px;
    justify-content: space-between;
    max-width: 670px;
    flex-wrap: wrap;

    a {
      font-size: rem(12px);
      border-radius: .20rem;
      transition: all .3s;
      padding: 0 16px;
      border-radius: .35rem;
      font-style: italic;
      display: flex;
      align-items: center;
      margin-right: 13px;

      &:last-of-type {
        margin-right: 0;
      }

      svg {
        margin: 0 1px;
      }
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.226);
        color: white;
        #elli {
          fill: #117dcb;
        }
      }
    }

    #elli {
      fill: rgba(0, 0, 0, 0);
      transition: fill .3s ease-in-out;
    }
  }
  
  @include mq($until: xxl) {
    &> div:first-of-type {

    }

    &__quick-contact {
      margin-left: -11px;
      a {
        padding: 0 11px;
      }
    }

    &__menu {

      &> ul {
  
        &> li{
          margin-left: 30px;
        }
      }
    }
  }

  @include mq($until: xl) {

    &__quick-contact {
      flex-direction: column;
    }

    &__contact {
      flex-direction: column;

      &-content {
        margin-bottom: 25px;
      }
    }
    
    &__menu {

      &> ul {
        &> li{
          margin-left: 0;
          margin-right: 4vw;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }

  @include mq($until: md) {
    flex-direction: column;

    &__quick-contact {
      flex-direction: row;
    }
    &__contact {
      flex-direction: row;

    }
  }
}

.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span{
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: $color-main;
      }
      img{
        margin-left: 15px;
      }
    }

    a, div, span{
      font-size: rem(15px);
      text-transform: uppercase;
      line-height: 1;
    }

    @include mq($until: xs) {
      
      a, div, span{
        font-size: rem(13px);
      }

      img {
        width: 20px;
        margin-left: 5px;
      }
    }
}