.article {

    display: flex;
    justify-content: space-between;

    &.left {
        flex-direction: row-reverse;
    }

    h2 {
        font-weight: 900;
        margin-bottom: 10px;
    }

    span {
        color: $color-extra;
        font-weight: 600;
    }

    p {
        margin-top: 40px;
        font-size: rem(15px);
        line-height: 2;
    }

    &__text-block {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    &__image {
        height: 38vw;
        width: 50vw;
        max-height: 940px;
        max-width: 1080px;
        margin-left: 60px;
        border-top-left-radius: $border-r;
        border-bottom-left-radius: $border-r;
        overflow: auto;
        display: flex;
        justify-content: right;

        img {
            height: 100%;
            object-fit: cover;
            object-position: left;
        }

        &.left {
            justify-content: left;
            border-top-right-radius: $border-r;
            border-bottom-right-radius: $border-r;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-right: 60px;
            margin-left: 0;

            img {
                object-position: right;
            }
        }
    }

    &__sub {
        margin-top: 40px;
        background-color: #F9F9F9;
        border-top-right-radius: $border-r;
        border-bottom-right-radius: $border-r;

        ul {
            display: flex;
            justify-content: space-between;
            height: 90px;
            align-items: center;
            li {
                padding-right: 30px;

                img {
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        h4 {
            font-style: italic;
            font-weight: 800;
            margin: 0 30px 0 0;
        }

        svg {
            width: 70px;
            height: 70px;
        }

        &.left {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: $border-r;
            border-bottom-left-radius: $border-r;

            ul {
                justify-content: flex-start;
                li {
                    padding-right: 0;
                    padding-left: 30px;
                }
            }
        }
    }

    @include mq($until: xxl) {

        &__logos {
            ul {
                li {
                    padding-right: 20px;
                    max-height: 22px;
                }
            }
        }
    }
  
    @include mq($until: md) {

        p {
            margin-top: 15px;
        }

        &__image {
            width: 30%;
            margin-left: 30px;
        }

        &__text-block {
            width: 65%;
        }

        &__sub {
            margin-top: 30px;
            ul {
                margin: 0;
            }
        }
    }

    @include mq($until: sm) {
        &__image {
            display: none;
        }
        &__text-block {
            width: 100%;
            padding-right: $padding-sm;
        }

        &.left {
            .article__text-block {
                padding-left: $padding-sm;
                padding-right: 0;
            }
        }
    }

    @include mq($until: xs) {
        &__text-block {
            padding-right: $padding-xs;
        }
        &.left {
            ul li {
                padding-left: 15px;

                h4 {
                    font-size: rem(16px)
                }

                &:first-of-type {
                    width: 100%;
                }
            }
            .article__text-block {
                padding-left: $padding-xs;

                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}