.brand {
    margin: 40px 0;
    background-color: #F9F9F9;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 61.5%;
    z-index: 1;
    right: 0;
    position: absolute;

    &-container {
        height: 160px;
    }

    div {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 40px;
        align-items: center;
    }

    img {
        width: 125px;
    }

    h3 {
        margin-bottom: 0;
    }

    @include mq($until: xxl) {
        width: 65.5%;
    }

    @include mq($until: xl) {
        width: 64%;
    }

    @include mq($until: lg) {
        width: 66.5%;
    }

    @include mq($until: md) {
        width: 67%;
    }

    @include mq($until: sm) {
        width: 98%;

        &-container {
            height: 110px;
        }

        img {
            width: 80px;
        }

        div {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-left: rem(17px);
        }
    }
}

.category {

    @include mq($until: xl) {
        padding-left: 25px;
    }

    @include mq($until: md) {
        padding-left: 0;
    }

    @include mq($until: sm) {
        &__content {
            text-align: justify;
        }
    }

}